import { ApplicationConfig } from '@angular/core';
import {InMemoryScrollingOptions, provideRouter, withInMemoryScrolling, withViewTransitions} from '@angular/router';

import { routes } from './app.routes';
import {provideHttpClient, withInterceptors} from '@angular/common/http';
import {errorHttpInterceptor} from "./helpers/error-http.interceptor";
import { provideClientHydration, withEventReplay, withHttpTransferCacheOptions } from '@angular/platform-browser';

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top'
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withViewTransitions(), withInMemoryScrolling(scrollConfig)),
    provideHttpClient(withInterceptors([errorHttpInterceptor])), 
    provideClientHydration(withEventReplay(), withHttpTransferCacheOptions({includePostRequests: true}))
  ]
};
